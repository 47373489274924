import { OrganizationClient, OrganizationFilterFields, PagedListOfOrganizationDTO, UpdateOrganizationCommand, } from "@/api/nswag.generated";
export default class OrganizationService {
    organizationsClient = new OrganizationClient();
    async getOrganizationsAsync(pageSize, pageNumber, orderBy, descending, searchQuery, filter) {
        if (filter == null) {
            filter = new OrganizationFilterFields();
        }
        const org = await this.organizationsClient.list(filter, pageSize, pageNumber, orderBy, descending, searchQuery);
        if (org && org.organizations) {
            return org.organizations;
        }
        return new PagedListOfOrganizationDTO();
    }
    async createAsync(command) {
        return await this.organizationsClient.create(command);
    }
    async updateAsync(organizationId, name, description, isActive, phoneNumber, avatar) {
        return await this.organizationsClient.put(organizationId, new UpdateOrganizationCommand({
            id: organizationId,
            name: name,
            description: description,
            isActive: isActive,
            phoneNumber: phoneNumber,
            avatar: avatar,
        }));
    }
    async getById(id) {
        return await this.organizationsClient.getById(id);
    }
}
