var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { VuexModule, Module, Action } from "vuex-class-modules";
import { PermissionsClient } from "@/api/nswag.generated";
import store from "@/store";
let PermissionsModule = class PermissionsModule extends VuexModule {
    async getRoles() {
        return await new PermissionsClient().getRolesPermissions();
    }
};
__decorate([
    Action
], PermissionsModule.prototype, "getRoles", null);
PermissionsModule = __decorate([
    Module
], PermissionsModule);
export const permissionsModule = new PermissionsModule({ store, name: "permissions" });
